const $ = require("jquery");
const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
const alertUnsuccess = '#alertUnsuccess';
const alertSuccess = '#alertSuccess';
$('#contact_submit').click(function (e) {
    console.log('hello')
    e.preventDefault();
    let name = $('#contact_name').val();
    let email = $('#contact_email').val();
    let message = $($(window).width() < 640 ? '#contact_message2' : '#contact_message').val();

    let dataString = 'name=' + name + '&email=' + email + '&message=' + message;
    if (name == '' || email == '' || message == '' || !re.test(email)) {
        console.log(dataString);
        $(alertUnsuccess).text('Vyplňte prosím správně všechna pole!');
        gsap.to(alertUnsuccess, {
            y: 555,
            yoyo: true,
            repeat: 1,
            repeatDelay: 1.5,
            autoAlpha: 1,
        });

    } else {
        $.ajax({
            type: "POST",
            url: "/api/contact",
            data: dataString,
            success: function (message) {
                console.log(message)
                if (message.success) {
                    gsap.to(alertSuccess, {
                        y: 555,
                        yoyo: true,
                        repeat: 1,
                        repeatDelay: 1.5,
                        autoAlpha: 1,
                    });
                    $('#contact_name').val('');
                    $('#contact_email').val('');
                    $('#contact_message').val('');
                    $('#contact_message2').val('');
                } else {
                    $(alertUnsuccess).text('Bohužel Vaše zpráva nebyla odeslána. Zkuste to prosím znovu.');
                    gsap.to(alertUnsuccess, {
                        y: 555,
                        yoyo: true,
                        repeat: 1,
                        repeatDelay: 1.5,
                        autoAlpha: 1,
                    });
                }
            },
            error: function (xhr, status, error) {
                $(alertUnsuccess).text('Bohužel Vaše zpráva nebyla odeslána. Zkuste to prosím znovu.');
                gsap.to(alertUnsuccess, {
                    y: 555,
                    yoyo: true,
                    repeat: 1,
                    repeatDelay: 1.5,
                    autoAlpha: 1,
                });
            }
        });
    }
    return false;
});

$('#burger').click(function () {
    $(this).toggleClass('is-open');
    $('.mobile-menu').toggleClass('is-open');
    $('footer, section').toggleClass('hidden');
})